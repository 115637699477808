import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import logo_dark from '../../assets/images/logo-dark.png';
import logo_light from '../../assets/images/logo-light.png';
import s1 from '../../assets/images/shop/items/s1.jpg';
import s2 from '../../assets/images/shop/items/s2.jpg';
import s3 from '../../assets/images/shop/items/s3.jpg';

import * as Icon from 'react-feather';
import {LuSearch, AiOutlineUser, LiaSignOutAltSolid, BsArrowDownCircle, BsHeartbreak, PiNoteDuotone, FaTiktok, FaFacebookF, FaInstagram} from '../../assets/icons/icons'

export default function EcommerceNavbar() {
    const [isMenu, setisMenu] = useState(false);
    const [navbarSticky , setNavbarSticky] = useState(false);
    const [isCart , setIsCart] = useState(false);
    const [isAccount , setIsAccount] = useState(false);
    const [showModal, setShowModel] = useState(false)

    window.addEventListener("scroll", windowScroll);
    function windowScroll(){
        setNavbarSticky( document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50);
    }
    function getClosest(elem, selector) {

        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) {}
                    return i > -1;
                };
        }
    
        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;
    
    };
    
    function activateMenu() {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {
    
            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }
    
            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');
             
             
                var immediateParent = getClosest(matchingMenuItem, 'li');
          
                if (immediateParent) {
                    immediateParent.classList.add('active');
                }
                
                var parent = getClosest(immediateParent, '.child-menu-item');
                if(parent){
                    parent.classList.add('active');
                }
    
                var parent = getClosest(parent || immediateParent , '.parent-menu-item');
            
                if (parent) {
                    parent.classList.add('active');
    
                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }
    
                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    }
    const toggleMenu = () => {
        setisMenu(!isMenu);
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        let handler = ()=>setIsCart(false)
        document.addEventListener("mousedown", handler);
        let account = ()=>setIsAccount(false)
        document.addEventListener("mousedown", account);
        let modal = ()=>setShowModel(false)
        document.addEventListener("mousedown", modal);

        const htmlTag = document.getElementsByTagName("html")[0]
        htmlTag.classList.remove('dark');
        activateMenu();
       
    }, []);
    
    return (    
        <nav id="topnav" className={`${navbarSticky ? "nav-sticky" :" defaultscroll"}`}>
            <div className="container relative">
                <Link className="logo">
                   <h3 className=' font-bold text-xl mt-6'>EricentClay</h3>
                </Link>

                <div className="menu-extras">
                    <div className="menu-item">
                        <Link className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <ul className="buy-button list-none mb-0 space-x-2">
                  

                <li className="inline"><Link to="https://www.tiktok.com/@ericent.clay?_t=8owx58vMwEc&_r=1 " target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaTiktok className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="https://www.facebook.com/profile.php?id=61560078196897&mibextid=ZbWKwL" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaFacebookF className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="https://www.instagram.com/ericentclay?igsh=cXF5eHFha3d2dTdp" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaInstagram className='text-sm'/></Link></li>
                </ul>

                <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}>
                    <ul className="navigation-menu">
                        <li><Link to="/shop" className="sub-menu-item">Home</Link></li>

                        <li><Link to="/about-us" className="sub-menu-item">About Us</Link></li>


                      


                        <li><Link to="/contact-us" className="sub-menu-item">Contact</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
