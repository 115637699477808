import React, { useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';

import d1 from '../../assets/images/shop/Detail/25.png';
import d2 from '../../assets/images/shop/Detail/26.png';
import d3 from '../../assets/images/shop/Detail/22.png';
import d4 from '../../assets/images/shop/Detail/23.png';
import d5 from '../../assets/images/shop/Detail/1.png';
import d6 from '../../assets/images/shop/Detail/2.png';
import d7 from '../../assets/images/shop/Detail/3.png';
import d8 from '../../assets/images/shop/Detail/16.png';
import d9 from '../../assets/images/shop/Detail/17.png';
import d10 from '../../assets/images/shop/Detail/13.png';
import d11 from '../../assets/images/shop/Detail/15.png';
import d12 from '../../assets/images/shop/Detail/4.png';
import d13 from '../../assets/images/shop/Detail/5.png';
import d14 from '../../assets/images/shop/Detail/7.png';
import d15 from '../../assets/images/shop/Detail/8.png';
import d16 from '../../assets/images/shop/Detail/9.png';
import d17 from '../../assets/images/shop/Detail/19.png';
import d18 from '../../assets/images/shop/Detail/20.png';
import d19 from '../../assets/images/shop/Detail/31.png';
import d20 from '../../assets/images/shop/Detail/33.png';
import d21 from '../../assets/images/shop/Detail/28.png';
import d22 from '../../assets/images/shop/Detail/29.png';
import d23 from '../../assets/images/shop/Detail/10.png';
import d24 from '../../assets/images/shop/Detail/11.png';

import d25 from '../../assets/images/shop/Detail/7.jpg';
import d26 from '../../assets/images/shop/Detail/9.jpg';
import d27 from '../../assets/images/shop/Detail/10.jpg';


import d28 from '../../assets/images/shop/Detail/17.jpg';
import d29 from '../../assets/images/shop/Detail/19.jpg';
import d30 from '../../assets/images/shop/Detail/18.jpg';

import d31 from '../../assets/images/shop/Detail/1.jpg';
import d32 from '../../assets/images/shop/Detail/2.jpg';
import d33 from '../../assets/images/shop/Detail/3.jpg';
import d34 from '../../assets/images/shop/Detail/4.jpg';
import d35 from '../../assets/images/shop/Detail/5.jpg';
import d36 from '../../assets/images/shop/Detail/6.jpg';

// import d37 from '../../assets/images/shop/1.jpg';
// import d38 from '../../assets/images/shop/2.jpg';


import d40 from '../../assets/images/shop/Detail/23.jpg';
import d41 from '../../assets/images/shop/Detail/24.jpg';
import d42 from '../../assets/images/shop/Detail/14.jpg';
import d43 from '../../assets/images/shop/Detail/16.jpg';
import EcommerceNavbar from '../../component/Navbar/ecommerceNavbar';
import ShopFooter from '../../component/Footer/shopFooter';
import { MdKeyboardArrowRight, PiWhatsappLogo } from '../../assets/icons/icons'
import TinySlider from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';

const settings = {
    container: '.tiny-single-item',
    items: 1,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 16,
}
export default function ShopItemDetail() {
    const { id } = useParams();
    console.log("🚀 ~ ShopItemDetail ~ id:", id)
    const productId = parseInt(id, 10);
    const location = useLocation();
    const [isOpenTab, setisOpen] = useState(0);
    const item = location.state?.item; // Accessing the passed item data
    const services = [
        {
            id: 1,
            images: [
                {
                    image: d1
                },
                {
                    image: d2

                }
            ]
        },
        {
            id: 2,
            images: [
                {
                    image: d3
                },
                {
                    image: d4

                }
            ]
        },
        {
            id: 3,
            images: [
                {
                    image: d5
                },
                {
                    image: d6

                }, {
                    image: d7

                }
            ]
        },
        {
            id: 4,
            images: [
                {
                    image: d8
                },
                {
                    image: d9

                }
            ]
        },
        {
            id: 5,
            images: [
                {
                    image: d10
                },
                {
                    image: d11

                }
            ]
        }, {
            id: 6,
            images: [
                {
                    image: d12
                },
                {
                    image: d13

                }
            ]
        },
        {
            id: 7,
            images: [
                {
                    image: d14
                },
                {
                    image: d15

                }, {
                    image: d16

                }
            ]
        },
        {
            id: 8,
            images: [
                {
                    image: d17
                },
                {
                    image: d18

                }
            ]
        },
        {
            id: 9,
            images: [
                {
                    image: d19
                },
                {
                    image: d20

                }
            ]
        },
        {
            id: 10,
            images: [
                {
                    image: d21
                },
                {
                    image: d22

                }
            ]
        }, {
            id: 11,
            images: [
                {
                    image: d23
                },
                {
                    image: d24

                }
            ]
        },
        {
            id: 12,
            images: [
                {
                    image: d25
                },
                {
                    image: d26

                },
                {
                    image: d27

                }
            ]
        },
        {
            id: 13,
            images: [
                {
                    image: d28
                },
                {
                    image: d29

                },
                {
                    image: d30

                }
            ]
        },
        {
            id: 14,
            images: [
                {
                    image: d31
                },
                {
                    image: d32

                },
                {
                    image: d33

                }
            ]
        },
        {
            id: 15,
            images: [
                {
                    image: d34
                },
                {
                    image: d35

                },
                {
                    image: d36

                }
            ]
        },
        // {
        //     id: 16,
        //     images: [
        //         {
        //             image: d37
        //         },
        //         {
        //             image: d38

        //         },
                
        //     ]
        // },
        {
            id: 17,
            images: [
                {
                    image:d40
                },
                {
                    image: d41

                },
              
            ]
        },
        {
            id: 18,
            images: [
                {
                    image:d42
                },
                {
                    image: d43

                },
              
            ]
        }
    ]
    const productImages = services.find((product) => product.id === productId);


    if (!item) {
        return <div>Item not found!</div>;
    }




    const handleTabClick = (index) => {
        setisOpen(index);
    };
    const handleStepDown = (e) => {
        const input = e.target.parentNode.querySelector('input[type="number"]')
        if (input) {
            input.stepDown();
        }
    };

    const handleStepUp = (e) => {
        const input = e.target.parentNode.querySelector('input[type="number"]')
        if (input) {
            input.stepUp();
        }
    };

    return (
        <>

            <EcommerceNavbar />

            <section className="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 mt-14">
                        <h3 className="text-3xl leading-normal font-semibold">Perfume Detail</h3>
                    </div>

                    <div className="relative mt-3">
                        <ul className="tracking-[0.5px] mb-0 inline-flex items-center space-x-1">
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600"><Link to="/shop">EricentClay</Link></li>
                            <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5"><MdKeyboardArrowRight className="text-xl" /></li>
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600" aria-current="page">  {item?.name || "N/A"}</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] items-center">
                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="tiny-single-item">
                                <TinySlider settings={settings}>
                                    {productImages && Array.isArray(productImages.images) && productImages.images.length > 0 && productImages.images.map((item, index) => (
                                        <div className="tiny-slide" key={index}>
                                            <img src={item.image} className="rounded-md shadow dark:shadow-gray-800" alt="" />
                                        </div>
                                    ))}
                                </TinySlider>
                            </div>
                        </div>

                        <div className="lg:col-span-7 md:col-span-6">
                            <div className="lg:ms-6">
                                <h5 className="text-2xl font-semibold">  {item?.name || "N/A"}</h5>
                                <div className="mt-2">
                                    <div className="mt-2 flex flex-row  items-center  content-center">
                                        <h5 className="text-lg font-semibold text-black">Price :</h5>
                                        <span className="text-slate-400 font-semibold ml-1">
                                        {item.descAmount} <del className='text-red-600 text-sm'>{item?.amount}</del>
                                        </span>
                                    </div>

                                </div>

                                <div className="mt-4">
                                    <h5 className="text-lg font-semibold">Description :</h5>
                                    <p className="text-slate-400 mt-2">{item?.description}</p>
                                    <h5 className="text-lg font-semibold mt-4">{item?.name} is a fragrance that:</h5>

                                    <div className="ml-[18px] mt-2">

                                        <ul className="list-disc text-black mt-2 ">
                                            {item?.points.length > 0 && item?.points.map((point, index) => (
                                                <li key={index} className="mb-2">
                                                    {point}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                                <div className="grid lg:grid-cols-2 grid-cols-1 gap-[30px] mt-4">
                                    <div className="flex items-center">
                                        <h5 className="text-lg font-semibold me-2">Size:</h5>
                                        <div className="space-x-1">
                                            <Link className=" h-10  w-12 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-md bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white">
                                                50ml
                                            </Link>
                                        </div>
                                    </div>


                                </div>

                                <div className="mt-4">
                                    <a
                                        href={`https://wa.me/${'+923190032529'}?text=Hello! I am interested to buy your ${item?.name || "N/A"} perfume?`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="py-2 px-5  font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white rounded-md me-2 mt-2 flex items-center justify-center"
                                    >
                                        <PiWhatsappLogo size={24} className="mr-2" /> Order Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid md:grid-cols-12 grid-cols-1 mt-10 gap-[30px]">
                        <div className="lg:col-span-3 md:col-span-5">
                            <div className="sticky top-20">
                                <ul
                                    className="flex-column p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md"
                                    id="myTab"
                                    data-tabs-toggle="#myTabContent"
                                    role="tablist"
                                >
                                    <li role="presentation">
                                        <button
                                            onClick={() => handleTabClick(0)}
                                            className={`${isOpenTab === 0
                                                ? "text-white bg-indigo-600 hover:text-white"
                                                : ""
                                                } px-4 py-2 text-start font-semibold rounded-md w-full mt-3 hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                                            id="dashboard-tab"
                                            data-tabs-target="#dashboard"
                                            type="button"
                                            role="tab"
                                            aria-controls="dashboard"
                                            aria-selected="false"
                                        >
                                            Notes
                                        </button>
                                    </li>
                                    <li role="presentation">
                                        <button
                                            onClick={() => handleTabClick(1)}
                                            className={`${isOpenTab === 1
                                                ? "text-white bg-indigo-600 hover:text-white"
                                                : ""
                                                } px-4 py-2 text-start font-semibold rounded-md w-full mt-3 hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                                            id="dashboard-tab"
                                            data-tabs-target="#dashboard"
                                            type="button"
                                            role="tab"
                                            aria-controls="dashboard"
                                            aria-selected="false"
                                        >
                                            Style
                                        </button>
                                    </li>
                                    <li role="presentation">
                                        <button
                                            onClick={() => handleTabClick(2)}
                                            className={`${isOpenTab === 2
                                                ? "text-white bg-indigo-600 hover:text-white"
                                                : ""
                                                } px-4 py-2 text-start font-semibold rounded-md w-full mt-3 hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                                            id="dashboard-tab"
                                            data-tabs-target="#dashboard"
                                            type="button"
                                            role="tab"
                                            aria-controls="dashboard"
                                            aria-selected="false"
                                        >
                                            Season
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="lg:col-span-9 md:col-span-7">
                            <div
                                id=""
                                className=" p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md"
                            >
                                {isOpenTab === 1 ? (
                                    <div
                                        className="myTabContent active"
                                        id="description"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                    >
                                        <p className="text-slate-400">
                                            {item?.Style || "N/A"}
                                        </p>
                                    </div>
                                ) : (
                                    ""
                                )}

                                {isOpenTab === 0 ? (
                                    <div
                                        className="myTabContent "
                                        id="addinfo"
                                        role="tabpanel"
                                        aria-labelledby="addinfo-tab"
                                    >
                                        <table className="w-full text-start">
                                            <tbody>
                                                <tr className="bg-white dark:bg-slate-900 border-b border-gray-100 dark:border-gray-700">
                                                    <td
                                                        className="font-semibold py-4"
                                                        style={{ width: "100px" }}
                                                    >
                                                        Top Notes
                                                    </td>
                                                    <td className="text-slate-400 py-4">
                                                        {item?.topNotes || "N/A"}
                                                    </td>
                                                </tr>

                                                <tr className="bg-white dark:bg-slate-900 border-b border-gray-100 dark:border-gray-700">
                                                    <td className="font-semibold py-4">Middle Notes</td>
                                                    <td className="text-slate-400 py-4">{item?.middleNotes || "N/A"}</td>
                                                </tr>

                                                <tr className="bg-white dark:bg-slate-900 border-b border-gray-100 dark:border-gray-700">
                                                    <td className="font-semibold py-4">Base Notes</td>
                                                    <td className="text-slate-400 py-4">
                                                        {item?.baseNotes || "N/A"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    ""
                                )}

                                {isOpenTab === 2 ? (
                                    <div
                                        className="myTabContent"
                                        id="review"
                                        role="tabpanel"
                                        aria-labelledby="review-tab"
                                    >
                                        {item?.Season || "N/A"}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>


            </section>
            <ShopFooter />
        </>
    )
}
