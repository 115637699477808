import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import ShopFooter from '../../component/Footer/shopFooter';
import EcommerceNavbar from '../../component/Navbar/ecommerceNavbar';
import { FaArrowRight, PiWhatsappLogo } from '../../assets/icons/icons'
import { productData } from '../../data/data';

export default function IndexShop() {
    const navigate = useNavigate();
    function calculateDiscount(originalPrice) {
        return originalPrice - (originalPrice * 0.20);
    }
    
    return (
        <>
            <EcommerceNavbar />
            <section className="relative mt-20">
                <div className="container-fluid relative ">
                    <div className="relative  py-72 table w-full shadow-md overflow-hidden ">
                        <div className="absolute  inset-0 bg-[url('../../assets/images/shop/bg1.png')]  md:bg-left bg-center bg-cover "></div>
                        <div className="absolute inset-0 bg-grey-950/30"></div>
                        {/* <div className="container relative">
                            <div className="grid grid-cols-1">
                                <div className="md:text-start text-center">
                                    <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6">Our Perfume <br /> Collections</h1>
                                    <p className="text-white/70 text-xl max-w-xl">We offer a diverse range of fragrances to suit every preference and personality. From fresh, citrusy scents that evoke the crispness of a Mediterranean summer to deep, woody fragrances that capture the mystery of a midnight forest, our collections are designed to cater to a wide array of tastes.</p>
                                    <div className="mt-6">
                                        <a   href={`https://wa.me/${'+923190032529'}?text=Hello! Hello! I am interested to buy your perfume?`} className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white rounded-md me-2 mt-2"><i className="mdi mdi-cart-outline"></i> Shop Now</a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            <section className="relative py-16">
                <div className="container relative mt-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center">
                        <div className="lg:col-span-8 md:col-span-6 md:text-start text-center">
                            <h3 className="text-2xl leading-normal font-semibold">Summer Deal</h3>
                        </div>
                        <div className="lg:col-span-4 md:col-span-6 md:text-end hidden md:block">
                            <Link to="/view-all-shop-items" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-slate-400 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">View More Items <FaArrowRight className="ms-2 text-[10px]" /></Link>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 mt-8 gap-[30px]">
                        {productData.map((item, index) => {
                            const originalAmount = parseFloat(item.amount.replace('RS ', '')); // Extract original price
                            const discountedAmount = calculateDiscount(originalAmount); // Calculate discounted price

                            return (
                                <div className="group" key={index}>
                                    <div className="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md transition-all duration-500">
                                        <div className='bg-white'>
                                        <img src={item.image} alt="" />
</div>
                                        {item.discount ? <ul className="list-none absolute top-[10px] end-4">
                                            <li><Link className={`bg-red-600 text-white text-[10px] font-bold px-1 py-0.5 rounded h-5`}>{item.discount} Off</Link></li>
                                        </ul> : ""}
                                        <div className="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 transition-all duration-500">
                                            <button
                                                onClick={() => {
                                                    navigate(`/shop-item-detail/${item.id}`, {
                                                        state: { item },
                                                    });
                                                }}
                                                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-900   border-slate-900 text-white w-full rounded-md"
                                            >
                                                <i className="mdi mdi-eye-outline mr-2"></i>
                                                Detail 
                                            </button>
                                        </div>
                                        {item.tag ? <ul className="list-none absolute top-[10px] start-4">
                                            <li><Link className={`${item.tagBg} text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5`}>{'Sale'}</Link></li>
                                        </ul> : ""}
                                    </div>
                                    <div className="mt-4">
                                        <Link to={`/shop-item-detail/${item.id}`} className=" hover:text-indigo-600 text-lg font-semibold">{item.name}</Link>
                                        <div className="flex justify-between items-center mt-1">
                                            <p className="text-green-600">{item.descAmount} <del className='text-red-600 text-sm'>{item?.amount}</del> </p>

                                        </div>
                                        <div className='flex '>
                                            <ul className="font-medium   text-amber-400 list-none">
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                            </ul>
                                        </div>
                                        <p className="text-red-600 text-sm">{item?.inspiredby && `Inspired By ${item.inspiredby} `} </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="grid md:grid-cols-12 grid-cols-1 md:hidden mt-8">
                        <div className="md:col-span-12 text-center">
                            <Link to="/view-all-shop-items" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-slate-400 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">View More Items <FaArrowRight className='ms-2 text-xs' /></Link>
                        </div>
                    </div>
                </div>
                <a
                    href={`https://wa.me/${'+923190032529'}?text=Hello! I am interested to buy your perfume?`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fixed  text-lg rounded-full z-10 bottom-5 end-2  p-3 text-center bg-green-600 text-white leading-9 flex items-center justify-center"
                >
                    <PiWhatsappLogo size={24} />
                </a>
            </section>
            <ShopFooter />
        </>
    )
}
