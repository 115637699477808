
import s1 from '../assets/images/shop/Landing/1.png';
import s2 from '../assets/images/shop/Landing/11.png';
import s3 from '../assets/images/shop/Landing/3.png';
import s4 from '../assets/images/shop/Landing/9.png';
import s5 from '../assets/images/shop/Landing/8.png';
import s6 from '../assets/images/shop/Landing/4.png';
import s7 from '../assets/images/shop/Landing/2.png';
import s8 from '../assets/images/shop/Landing/10.png';
import s9 from '../assets/images/shop/Landing/6.png';
import s10 from '../assets/images/shop/Landing/5.png';
import s11 from '../assets/images/shop/Landing/7.png';
import s12 from '../assets/images/shop/Landing/17.jpg';
import s13 from '../assets/images/shop/Landing/15.jpg';
import s14 from '../assets/images/shop/Landing/16.jpg';
import s15 from '../assets/images/shop/Landing/12.jpg';
import s16 from '../assets/images/shop/Landing/18.jpg';
import s17 from '../assets/images/shop/Landing/19.jpg';
import s18 from '../assets/images/shop/Landing/14.jpg';




export const productData = [
    {
        id: 1,
        image: s1,
        name: "Bend It",
        amount: "RS 1890",
        discount: "20%",
        descAmount: "RS 1515",
        tag: "New",
        sex:'Men',
        tagBg: "bg-slate-700",
        inspiredby: "David Beckham",
        description: "Unleash your inner strength and confidence with Bend It, a fresh and energetic perfume inspired by the iconic David Beckham. This invigorating scent is perfect for spring and summer, evoking the thrill of the game and the rush of adrenaline.",
        topNotes: "Vetiver, Bergamot, and Mandarin Orange ignite the senses with a fresh and citrusy burst, like the kick-off of a match",
        middleNotes: "Cardamom and Patchouli add a spicy and herbal depth, like the intensity of the game",
        baseNotes: "White Amber provides a smooth and sensual dry down, like the satisfaction of victory",
        Style: "Fresh, Energetic, Confident, Invigorating, Masculine",
        Season: "Perfect for Spring and Summer",
        points: ['Embodies the essence of spring and summer with its fresh, energetic, and invigorating aroma',
            'Captivates the senses with its unique blend of vetiver, cardamom, and patchouli, perfect for a man who embodies strength and confidence',
            `Lasts throughout the day, leaving a lasting impression like a winning goal`,
        ],
    },
    {
        id: 2,
        image: s2,
        name: "Boss Aura",
        amount: "RS 1800",
        descAmount: "RS 1440",
        tag: "New",
        tagBg: "bg-orange-600",
        discount: "20%",
        inspiredby: "Hugo Boss",
        sex:'Men',
        points: ['Embodies the essence of spring and summer with its fresh, woody, and spicy aroma',
            'Captivates the senses with its unique blend of apple, cinnamon, and cedar, perfect for a man who exudes confidence and charisma',
            `Lasts throughout the day, leaving a lasting impression like a boss's signature`,
        ],
        description: "Unleash your inner leader with Boss Aura, a woody spicy perfume inspired by the iconic Hugo Boss. This invigorating scent is perfect for spring and summer, evoking the confidence and charisma of a true boss.",
        topNotes: "Apple, Plum, Lemon, Bergamot, and Oakmoss ignite the senses with a fresh and fruity burst, like a bold move.",
        middleNotes: "Cinnamon and Mahogany add a warm and spicy depth, like a leader's passion.",
        baseNotes: "Vanilla, Olive Tree, Cedar, and Sandalwood provide a smooth and woody dry down, like a lasting legacy.",
        Style: "Woody, Spicy, Fresh, Confident, Masculine",
        Season: "Perfect for Spring and Summer",
      
    },
    {
        id: 3,
        image: s3,
        name: "Botancial Beauty",
        amount: "RS 2200",
        tagBg: "bg-pink-500",
        descAmount: "RS 1760",
        inspiredby: "Gucci Flora",
        sex:'Women',
        discount: "20%",
        description: "Embrace the essence of spring and summer with Botanical Beauty, a vibrant and alluring perfume inspired by the lush gardens of Gucci Flora. This radiant scent captures the beauty of nature, blending fresh florals and green notes  to evoke the feeling of a warm sunny day.",
        topNotes: "Red Berries, Pear Blossom, Italian Mandarin",
        middleNotes: "Frangipari, Jasmine, Gardenia",
        baseNotes: "Brown Sugar, Patchouli",
        Season: "Perfect for spring and summer, Botanical Beauty is a refreshing and elegant perfume that will transport you to a world of beauty and wonder.",
        Style: "Feminine, Fresh, Floral, Green",
        tag: "New",
        points: [
            'Embodies the essence of summer and spring with its fresh, floral, and vibrant aroma',
            'Captivates the senses with its unique blend of pear blossom, gardenia, and brown sugar, perfect for a woman who embodies beauty and grace',
            'Lasts throughout the day, leaving a lasting impression like a blooming flower'
        ]
    },
    {
        id: 4,
        image: s4,
        name: "Whispering Petals",
        amount: "RS 1890",
        descAmount: "RS 1515",
        discount: "20%",
        inspiredby: "Gucci Bloom",
        sex:'Women',
        description: "Welcome the beauty of spring with Whispering Petals, a delicate and enchanting perfume inspired by the lush gardens of Gucci Bloom. This soft and feminine scent captures the gentle whispers of blooming flowers, transporting you to a world of serenity and grace.",
        topNotes: "Jasmine",
        middleNotes: "Tuberose",
        baseNotes: "Rangoon Creeper.",
        Style: "Feminine, Delicate, Floral, Fresh, Soft",
        Season: "Perfect for spring, Whispering Petals is a delicate and enchanting perfume that will accompany you through the season's blossoming beauty.",
        points: [
            'Embodies the essence of summer and spring with its fresh, floral, and delicate aroma',
            'Captivates the senses with its unique blend of jasmine, tuberose, and Rangoon creeper, perfect for a woman who embodies grace and femininity',
            'Lasts throughout the day, leaving a lasting impression like a gentle whisper'
        ],
        tag: "New",
        tagBg: "bg-gray-400",
    },
    {
        id: 5,
        image: s5,
        name: "Green Mile",
        amount: "RS 1700",
        tag: "New",
        discount: "20%",
        inspiredby: "Armani Mania",
        tagBg: "bg-green-500",
        descAmount: "RS 1360",
        sex:'Women',
        points: ['Embodies the essence of fall and winter with its warm, spicy, and inviting aroma',
            'Captivates the senses with its unique blend of labdanum, nutmeg, and guaiac wood, perfect for a man who exudes confidence and sophistication',
            ' Lasts throughout the day, leaving a lasting impression like a warm memory',
        ],
        description: "Embrace the warmth and sophistication of Green Mile, an amber spicy perfume inspired by the iconic Armani Mania. This rich and inviting scent is perfect for fall and winter, evoking the comfort and luxury of a crackling fire.",
        topNotes: "Labdanum, Vanilla, and Orange Blossom ignite the senses with a sweet and creamy burst, like a warm embrace.",
        middleNotes: "Nutmeg and Saffron add a spicy and exotic depth, like a winter's night.",
        baseNotes: "White Musk and Guaiac Wood provide a sensual and woody dry down, like a cozy retreat.",
        Style: "Amber, Spicy, Warm, Sophisticated, Masculine.",
        Season: "Perfect for Fall & Winter",

    },
    {
        id: 6,
        image: s6,
        name: "Diver",
        amount: "RS 1890",
        sex:'Men',
        inspiredby: "Blue de Channel",
        descAmount: "RS 1515",
        tag: "New",
        tagBg: "bg-blue-400",
        discount: "20%",
        description: "Dive into the unknown with Diver, a woody aromatic perfume for men that embodies the thrill of adventure and exploration. Inspired by the iconic Bleu de Chanel, this scent is perfect for all seasons, evoking the freshness of the ocean and the warmth of the sun.",
        topNotes: "Grapefruit, Lemon, Mint, Pink Pepper, Bergamot, Aldehydes, and Coriander create a fresh and invigorating splash, like a dive into the ocean",
        middleNotes: "Ginger, Nutmeg, Jasmine, and Melon add a spicy and floral depth, like a treasure discovered on the ocean floor",
        baseNotes: " Amber, Cedar, Sandalwood, Patchouli, and Labdanum provide a warm and woody dry down, like a sunset on the beach",
        Style: "Woody, Aromatic, Fresh, Adventurous, Confident",
        Season: "All seasons - perfect for any time of year.",
        points: ['Embodies the essence of adventure, exploration, and freedom',
            'Captivates the senses with its unique blend of fresh, woody, and aromatic notes',
            'Lasts throughout the day, leaving a lasting impression like a message in a bottle'
        ],


    },
    {
        id: 7,
        image: s7,
        name: "Golden Odyssey",
        amount: "RS 1950",
        inspiredby: "Baccarat Rouge 540",
        descAmount: "RS 1560",
        discount: "20%",
        sex:'Unisex',
               description: "Embark on a luxurious journey with Golden Odyssey, a rich and opulent perfume inspired by the majesty of Baccarat Rouge 540. This warm and seductive scent is perfect for winter and fall, evoking the comfort and sophistication of a golden sunset.",
        topNotes: "Jasmine, Saffron",
        middleNotes: "Amber wood",
        baseNotes: "Fir Resin, Cedar",
        Style: "Luxurious, Opulent, Warm, Seductive,",
        Season: "Perfect for winter and fall, Golden Odyssey is a warm and seductive perfume that will accompany you through the cooler months with comfort and sophistication.",
        points: [' Embodies the essence of fall and winter with its warm, spicy, and floral aroma',
            'Captivates the senses with its unique blend of saffron, amber, and fir resin, perfect for those who appreciate luxury and sophistication',
            'Lasts throughout the day, leaving a lasting impression like a golden legacy',
        ],
        tag: "New",
        tagBg: "bg-amber-600",
    },
    {
        id: 8,
        image: s8,
        name: "White Wolf",
        amount: "RS 2500",
        descAmount: "RS 2000",
        inspiredby: "Aventus Creed",
        tag: "New",
        sex:'Men',
        discount: "20%",
        description: "Unleash your inner strength and sophistication with White Wolf, a bold and seductive perfume inspired by the iconic Aventus Creed. This powerful scent is perfect for spring, summer, and fall, evoking the freedom and adventure of the wild.",
        topNotes: "Bergamot, Lemon, Pink pepper, Blackcurrant, Apple",
        middleNotes: "Pineapple, Moroccan Jasmine, Patchouli",
        baseNotes: "Cedarwood, Ambroxan, Birch, Oak Moss, Musk",
        Style: "Bold, Sophisticated, Fresh, Woody",
        Season: "Perfect for spring, summer, and fall, White Wolf is a versatile and alluring perfume that will accompany you on all your adventures.",
       
        points: ['Embodies the essence of summer and spring with its fresh, fruity, and adventurous aroma',
            'Captivates the senses with its unique blend of bergamot, pineapple, and cedarwood, perfect for a man who embodies confidence and wildness',
            `Lasts throughout the day, leaving a lasting impression like a wolf's mark`,
        ],
        tagBg: "bg-slate-900",
    },
    {
        id: 9,
        image: s9,
        name: "The Beast",
        amount: "RS 1950",
        inspiredby: "Suavage Dior",
        descAmount: "RS 1560",
        tag: "New",
        sex:'Men',
        tagBg: "bg-blue-950",
        description: `Unleash your inner beast with this bold and seductive aromatic fragrance for men, inspired by the iconic Sauvage. The Beast is a scent that embodies the untamed and the unapologetic, perfect for all seasons.`,
        topNotes: "Calabrian Pepper ignites the senses with a spicy and fiery burst, like a beast unleashed",
        middleNotes: "Sichuan Pepper and Vetiver add a mysterious and earthy depth, like a forest at dusk",
        baseNotes: "Ambroxan, Cedar, and Labdanum provide a sensual and woody dry down, like a beast's lair",
        Style: "Aromatic, Spicy, Earthy, Seductive, Powerful",
        Season: "All seasons - perfect for any time of year.",
        points: ['Embodies the essence of power, seduction, and freedom',
            'Captivates the senses with its unique blend of spicy, earthy, and woody notes',
           ` Lasts throughout the day, leaving a lasting impression like a beast's mark`
        ],
    }, 
    {
        id: 10,
        image: s10,
        name: "King's Scent",
        amount: "RS 2300",
        inspiredby: "Erba Pura",
        descAmount: "RS 1840",
        tag: "New",
        discount: "20%",
        sex:'Men',
        tagBg: "bg-gray-500",
        description:` Indulge in the majesty of King's Scent, an amber fragrance inspired by the iconic Erba Pura. This regal scent is perfect for summer, autumn, and winter, evoking the warmth and opulence of a luxurious kingdom.`,
        topNotes: " Sicilian Orange, Sicilian Vanilla, and Calabrian Bergamot ignite the senses with a sweet and citrusy burst, like a golden crown",
        middleNotes: "Fruits add a juicy and succulent depth, like a lavish feast",
        baseNotes: "White Musk, Vanilla, and Amber provide a rich and velvety dry down, like a king's throne",
        Style: "Amber, Sweet, Fruity, Luxurious, Regal",
        Season: "Perfect for Summer, Autumn and Winter",
        points: ['Embodies the essence of luxury, warmth, and opulence',
            'Captivates the senses with its unique blend of sweet, fruity, and amber notes',
            `Lasts throughout the day, leaving a lasting impression like a royal legacy`
        ],
    }, 
    {
        id: 11,
        image: s11,
        name: "Deep Sea",
        amount: "RS 2200",
        inspiredby: "Dylan Blue",
        descAmount: "RS 1760",
        tag: "New",
        discount: "20%",
        tagBg: "bg-blue-900",
        description: `Dive into the depths of the ocean with Deep Sea, an amber aromatic fragrance inspired by the iconic Dylan Blue. This scent is perfect for summer, autumn, and winter, evoking the mystery and power of the deep sea.`,
        topNotes: "Calabrian Bergamot and Water Notes ignite the senses with a fresh and aquatic burst, like a splash of ocean water",
        middleNotes: "Ambroxan, Black Pepper, and Patchouli provide a sensual and spicy depth, like a sunken ship",
        baseNotes: "Musk and Saffron provide a warm and seductive dry down, like a treasure chest",
        Style: "Amber, Aromatic, Fresh, Aquatic, Seductive",
        Season: "Perfect for Summer, Autumn and Winter",
        points: [`Embodies the essence of the ocean's power and mystery`,
            'Captivates the senses with its unique blend of fresh, aquatic, and amber notes',
            `Lasts throughout the day, leaving a lasting impression like a message in a bottle`
        ],
    },
    {
        id: 13,
        image: s12,
        name: "Royal Desire",
        discount: "20%",
        amount: "RS 1800",
        // inspiredby: "Dylan Blue",
        descAmount: "RS 1440",
        tag: "New",
        sex:'Men',
        tagBg: "bg-orange-600",
        description: `Experience the regal essence of Royal Resire, a fragrance that commands attention and inspires admiration. Wear your majesty with confidence, and make every moment unforgettable.`,
        topNotes: "Nutmeg's warm, aromatic spice, Lemon's invigorating citrus, and Jasmine's subtle, floral nuance.",
        middleNotes: "Clary Sage's herbaceous, slightly sweet undertones, adding a touch of refined elegance.",
        baseNotes: "Vetiver's earthy, woody depth and Oak Moss's forest floor richness, evoking a sense of luxury and nobility.",
        Style: " Aromatic Fougère, Woody, Oriental",
        Season: "Perfect for all seasons",
        points: [`Business meetings and formal events`,
            'Everyday wear for a touch of elegance',
            `Evening wear for a sophisticated, seductive charm`
        ],
    },
   
    {
        id: 12,
        image: s13,
        name: "Gentleman",
        amount: "RS 2970",
        inspiredby: "Office Men",
        descAmount: "RS 2375",
        tag: "New",
        discount: "20%",
        sex:'Men',
        tagBg: "bg-gray-600",
        description: `Embody the essence of refined sophistication with Gentleman, a fragrance that combines fresh, woody, and sensual notes. Perfect for the modern man who desires a scent that exudes confidence and elegance.`,
        topNotes: "Ambroxan's fresh, aquatic nuance, evoking the great outdoors",
        middleNotes: "Cashalox's woody, leathery charm, Orris Root's subtle, powdery elegance",
        baseNotes: "Jasmine's sweet, floral sensuality, Musk's sensual, skin-like warmth",
        Style: "Woody, Fresh, Oriental",
        Season: "Perfect for  Spring, Summer, Fall",
        points: [` Men who appreciate a fresh, woody scent with a sensual twist`,
            `Individuals who desire a fragrance that's perfect for everyday wear, evening wear, or special occasions`,
            `Ambroxan's fresh, aquatic nuance, evoking the great outdoors`
        ],
    },
    {
        id: 14,
        image: s14,
        name: "Phruity",
        amount: "RS 1800",
        // inspiredby: "Dylan Blue",
        descAmount: "RS 1440",
        tag: "New",
        discount: "20%",
        tagBg: "bg-orange-400",
        description: `Indulge in the captivating essence of Phruity, a woody musk fragrance for women. This alluring scent embodies the vibrant spirit of Spring, Summer, and Fall, with a sensual blend of citrus, florals, and musk.`,
        topNotes: "Mandarin's juicy sweetness, Martini's tangy twist",
        middleNotes: "Orchid's exotic, floral charm, Musk's sensual, skin-like warmth.",
        baseNotes: "White Amber's smooth, creamy depth",
        Style: "Woody Musk, Floral, Citrus",
        Season: "Perfect for Spring, Summer, Fall",
        points: [`The modern woman who embodies confidence, femininity, and a touch of playfulness`,
            `Individuals who desire a fragrance that's perfect for everyday wear, evening wear, or special occasions`,
            `Suitable for women of all ages who love woody musk fragrances with a floral and citrus twist.`
        ],
    },
    {
        id: 15,
        image: s15,
        name: "Her",
        amount: "RS 1600",
        // inspiredby: "Dylan Blue",
        descAmount: "RS 1280",
        tag: "New",
        sex:'Unisex',
        discount: "20%",
        tagBg: "bg-red-800",
        description: `Experience the captivating scent of 'Her', a fragrance for women that embodies the essence of elegance and sophistication. This alluring perfume is perfect for Spring and Winter, with a unique blend of warm, sensual notes.`,
        topNotes: "Vanilla's creamy sweetness, inviting and comfortingOlibanum's resinous, mystical charm, evoking ancient wisdom",
        middleNotes: "Tonka Bean's rich, velvety smoothness, adding depth and sensuality",
        baseNotes: "White Amber's smooth, creamy depth",
        Style: "Oriental, Woody, Vanilla",
        Season: "Perfect for Spring, Winter",
        points: [`Women who appreciate a warm, sensual scent with a rich, velvety smoothness`,
            `Individuals who desire a fragrance that's perfect for everyday wear, evening wear, or special occasions`,
            `Suitable for women of all ages who love oriental, woody fragrances with a vanilla twist.`
        ],
    },
    // {
    //     id: 16,
    //     image: s16,
    //     name: "Kiss of Midnight",
    //     amount: "RS 2030",
    //     sex:'Women',
    //     // inspiredby: "Dylan Blue",
    //     descAmount: "RS 1625",
    //     tag: "New",
    //     discount: "20%",
    //     tagBg: "bg-blue-600",
    //     description: `Indulge in the alluring essence of Spring and Summer with Kiss of Midnight, an aquatic fragrance for women. This captivating scent embodies the mystery and sensuality of a midnight kiss under the stars.`,
    //     topNotes: "Ambergris and Musk's sensual, skin-like warmth, like a whispered promise.",
    //     middleNotes: "Cinnamon's spicy spark, evoking the thrill of a secret encounter.",
    //     baseNotes: "Apple's crisp freshness and Blossom's floral delicacy, like the gentle lapping of waves on a moonlit beach.",
    //     Style: "Aquatic, Floral, Sensual",
    //     Season: "Perfect for Spring, Summer",
    //     // points: [`Embodies the essence of the ocean's power and mystery`,
    //     //     'Captivates the senses with its unique blend of fresh, aquatic, and amber notes',
    //     //     `Lasts throughout the day, leaving a lasting impression like a message in a bottle`
    //     // ],
    // },
   
    {
        id: 17,
        image: s17,
        name: "Him",
        amount: "RS 1800",
        // inspiredby: "Office Men",
        descAmount: "RS 1440",
        tag: "New",
        discount: "20%",
        sex:'Men',
        tagBg: "bg-blue-500",
        description: `Embody the essence of masculine sophistication with 'Him', a fragrance that combines woody, oriental, and fresh notes. Perfect for the modern man who desires a scent that exudes confidence and elegance.`,
        topNotes: "Sandalwood's warm, woody nuance, evoking the great outdoors",
        middleNotes: "Cashalox's woody, leathery charm, Orris Root's subtle, powdery elegance",
        baseNotes: "Basil's fresh, herbaceous charm, Tonka Bean's sweet, creamy nuance, Juniper's crisp, woody freshness",
        Style: " Aromatic, Woody, Oriental",
        Season: "Perfect for Spring, Winter",
        points: [`Men who appreciate a woody, aromatic scent with a sweet and fresh twist`,
            `Suitable for men of all ages who love aromatic fragrances with a woody, oriental twist.`,
            `Individuals who desire a fragrance that's perfect for everyday wear, evening wear, or special occasions
`
        ],
    },
    {
        id: 18,
        image: s18,
        name: "Fifth Hour",
        amount: "RS 1900",
        // inspiredby: "Office Men",
        descAmount: "RS 1520",
        tag: "New",
        discount: "20%",
        sex:'Men',
        tagBg: "bg-amber-600",
        description: `Embark on a journey of adventure and freedom with Fifth Hour, a fragrance that combines fresh, spicy, and woody notes. Perfect for the modern man who desires a scent that exudes confidence and energy.`,
        topNotes: "Lemon's citrusy freshness, Ginger's spicy, invigorating warmth",
        middleNotes: "Lavender's subtle, herbaceous charm, Mint's cool, refreshing nuance",
        baseNotes: "Apple's sweet, fruity warmth, Cardamom's spicy, aromatic depth",
        Style: "Woody Spicy, Fresh, Oriental",
        Season: "Perfect for Spring, Winter",
        points: [`Men who appreciate a woody, spicy scent with a fresh and fruity twist`,
            `Individuals who desire a fragrance that's perfect for everyday wear, evening wear, or special occasions`,
            `Suitable for men of all ages who love woody spicy fragrances with a fresh, oriental twist.`
        ],
    },
];