import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import Navbar from './component/Navbar/navbar';
import ShopGridTwo from './pages/ecommerce/shopGridTwo';
import ShopItemDetail from './pages/ecommerce/shopItemDetail';
import ShopAbout from './pages/ecommerce/shopAbout';
import PageThankyou from './pages/special/pageThankyou';
import PageError from './pages/special/pageError';
import PageComingsoon from './pages/special/pageComingsoon';
import IndexShop from './pages/index/indexShop';
import ContactOne from './pages/contact/contactOne';
import Switch from './component/Switch';
export default function App() {
    return (
        <BrowserRouter>
        <Switch/>
            <Routes>
                <Route path="/" element={<Navigate to="/shop" />} />
                <Route exact path="*" element={<Navigate to="/page-notfound-error"/>} />
                <Route exact path="/view-all-shop-items" element={<ShopGridTwo />} />
                <Route exact path="/shop-item-detail" element={<ShopItemDetail />} />
                <Route exact path="/shop-item-detail/:id" element={<ShopItemDetail />} />
                <Route exact path="/navbar" element={<Navbar />} />
                <Route exact path="/about-us" element={<ShopAbout />} />           
                <Route exact path="/page-thankyou" element={<PageThankyou />} />
                <Route exact path="/page-notfound-error" element={<PageError />} />
                <Route exact path="/page-comingsoon" element={<PageComingsoon />} />
                <Route exact path="/shop" element={<IndexShop />} />
                <Route exact path="/contact-us" element={<ContactOne />} />
            </Routes>
        </BrowserRouter>
    )
}
