import React from "react";
import { Link } from "react-router-dom";
import {FaFacebookF, FaInstagram, BiCreditCardAlt, TfiTruck, BsArchive, FaTiktok} from '../../assets/icons/icons'
export default function ShopFooter(){
    return(
        <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">    
        <div className="container relative">
            <div className="grid grid-cols-12">
                <div className="col-span-12">
                    <div className="py-[60px] px-0">
                        <div className="grid grid-cols-1 gap-[30px]">
                            <div className="lg:col-span-3 md:col-span-12">
                                <Link to="#" className="text-[22px] focus:outline-none">
                                    <h5>EricentClay</h5>
                                </Link>
                                <p className="mt-6 text-gray-300">We invite you to explore our collection and discover the scent that speaks to you. Whether you are looking for something bold and daring, soft and subtle, or timeless and classic, EricentClay has a fragrance that will enhance your presence and leave a lasting impression. Our fragrances are crafted to suit every mood, occasion, and style, offering a signature scent for everyone</p>
                                <ul className="list-none mt-5 space-x-1 space-y-1">
                                    <li className="inline"><Link to="https://www.tiktok.com/@ericent.clay?_t=8owx58vMwEc&_r=1 " target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaTiktok className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="https://www.facebook.com/profile.php?id=61560078196897&mibextid=ZbWKwL" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaFacebookF className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="https://www.instagram.com/ericentclay?igsh=cXF5eHFha3d2dTdp" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaInstagram className='text-sm'/></Link></li>
                                </ul>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1">
                <div className="py-[30px] px-0 border-t border-slate-800">
                    <div className="grid lg:grid-cols-3 md:grid-cols-2">
                        <div className="flex items-center lg:justify-center">
                            <TfiTruck className="align-middle text-lg mb-0 me-2"/>
                            <h6 className="mb-0 font-medium">Free delivery</h6>
                        </div>
                        <div className="flex items-center lg:justify-center">
                            <BsArchive className="align-middle text-lg mb-0 me-2"/>
                            <h6 className="mb-0 font-medium">Efficient shipping</h6>
                        </div>
                        <div className="flex items-center lg:justify-center">
                            <BiCreditCardAlt className="align-middle text-lg mb-0 me-2"/>
                            <h6 className="mb-0 font-medium">Money-back guarantee</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
}