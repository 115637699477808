import React from 'react'
import { Link } from 'react-router-dom';
import EcommerceNavbar from '../../component/Navbar/ecommerceNavbar';
import ShopFooter from '../../component/Footer/shopFooter';
import * as Icon from 'react-feather'
import { MdKeyboardArrowRight, BiCreditCardAlt, GoLocation, BiTimer, FaMoneyBillWave } from '../../assets/icons/icons'

export default function ShopAbout() {
    const features = [
        {
            icon: GoLocation,
            title: 'Nationwide Delivery',
            desc: "Enjoy delivery all over Pakistan for just 200 Rs."
        },
        {
            icon: BiTimer,
            title: 'Long-Lasting Perfume',
            desc: "Experience the enduring fragrance that lasts all day."
        },
        {
            icon: BiCreditCardAlt,
            title: 'Flexible Payment Options',
            desc: "Choose from Easypaisa, Cash on Delivery, and more."
        },
        {
            icon: FaMoneyBillWave,
            title: 'Money Back Guarantee',
            desc: "Satisfaction guaranteed or your money back."
        },
    ]
    
    return (
        <>
            <EcommerceNavbar />
            <section className="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-14">
                        <h3 className="text-3xl leading-normal font-semibold">"Ericent Clay - The Fragrance of New Era</h3>
                    </div>

                    <div className="relative text-center mt-3">
                        <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600"><Link to="/shop">EricentClay</Link></li>
                            <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5"><MdKeyboardArrowRight className="text-xl" /></li>
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600" aria-current="page">About us</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="md:flex justify-center">
                        <div className="lg:w-3/5 text-center">
                            <h5 className="text-2xl font-semibold">Our Journey</h5>

                            <p className="text-slate-400 mt-4">Welcome to EricentClay, where the art of fragrance is crafted with passion and precision. At EricentClay, we believe that a perfume is more than just a scent—it's a statement, an expression of individuality, and a journey into the soul. Our journey began with a simple desire: to create unique, high-quality fragrances that resonate with the diverse personalities of our customers. Each EricentClay perfume is meticulously designed using the quality oils.</p>
                        </div>
                    </div>
                </div>
                <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Collections
                        </h3>

                        <p className="text-slate-400 max-w-xl mx-auto">We offer a diverse range of fragrances to suit every preference and personality. From fresh, citrusy scents that evoke the crispness of a Mediterranean summer to deep, woody fragrances that capture the mystery of a midnight forest, our collections are designed to cater to a wide array of tastes.</p>
                    </div>
                </div>
                <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Why EricentClay?

                        </h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Choosing EricentClay means choosing a brand that values authenticity, quality, and individuality. We understand that perfume is a deeply personal choice, and we are here to help you find the scent that resonates with your essence. Our customer service team is passionate about guiding you through our collections, offering personalized recommendations to ensure that your EricentClay experience is nothing short of exceptional.</p>
                    </div>
                </div>
                <div className="container relative md:mt-24 mt-16">
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-4 gap-[30px]">
                        {features.map((item, index) => {
                            let Icons = item.icon
                            return (
                                <div key={index} className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                                    <div className="relative overflow-hidden text-transparent -m-3">
                                        <Icon.Hexagon className="h-28 w-28 fill-indigo-600/5 mx-auto rotate-[30deg]"></Icon.Hexagon>
                                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                            <Icons className='w-8 h-8' />
                                        </div>
                                    </div>

                                    <div className="mt-6">
                                        <Link className="text-xl font-medium hover:text-indigo-600 duration-500 ease-in-out">{item.title}</Link>
                                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3">{item.desc}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <ShopFooter />
        </>
    )
}
