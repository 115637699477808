import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import ShopFooter from '../../component/Footer/shopFooter';
import EcommerceNavbar from '../../component/Navbar/ecommerceNavbar';
import Baccarat from "../../assets/images/shop/Landing/2.png";
import Flora from "../../assets/images/shop/Landing/3.png";
import { FaSearch, MdKeyboardArrowRight, MdOutlineKeyboardArrowLeft } from "../../assets/icons/icons"
import deepsea from "../../assets/images/shop/Landing/8.png";
import { productData } from '../../data/data';


export default function ShopGridTwo() {
    const navigate = useNavigate();
    const [shopGridData, setShopGridData] = useState(productData);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const itemsPerPage = 10;
    const totalPages = Math.ceil(shopGridData.length / itemsPerPage);
    const currentProducts = shopGridData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };
    const onClickCategory = (gender) => {
        const filteredData = productData.filter(data => data.sex === gender);
        setShopGridData(filteredData);
        setCurrentPage(1);
    };
    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);

        const filteredData = productData.filter(item =>
            item.name.toLowerCase().includes(value)
        );
        setShopGridData(filteredData);
        setCurrentPage(1);
    };

    const resetFilters = () => {
        setShopGridData(productData);
        setSearchTerm('');
        setCurrentPage(1);
    };
    const sexData = [
        { sex: 'Men' },
        { sex: 'Women' },
        { sex: 'Unisex' }
    ];
    return (
        <>
            <EcommerceNavbar />
            <section className="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 mt-14">
                        <h3 className="text-3xl leading-normal font-semibold">View All Items</h3>
                    </div>
                    <div className="relative mt-3">
                        <ul className="tracking-[0.5px] mb-0 inline-flex items-center space-x-1">
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                                <Link to="/shop">EricentClay</Link>
                            </li>
                            <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5">
                                <MdKeyboardArrowRight className="text-xl" />
                            </li>
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600" aria-current="page">
                                All Perfumes
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-4 md:col-span-6">
                            <div className="shadow dark:shadow-gray-800 p-6 rounded-md bg-white dark:bg-slate-900 sticky top-20">
                                <form>
                                    <div className="grid grid-cols-1 gap-3">
                                        <div>
                                            <label htmlFor="searchname" className="hidden font-semibold"></label>
                                            <div className="relative">
                                                <FaSearch className="w-4 h-4 absolute top-3 start-3" />
                                                <input
                                                    name="search"
                                                    id="searchname"
                                                    type="text"
                                                    className="form-input w-full py-2 px-3 h-10 ps-9 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                                    placeholder="Search"
                                                    value={searchTerm}
                                                    onChange={handleSearch}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label className="font-semibold">Categories</label>
                                            {sexData.map((data, index) => (
                                                <div className="block mt-2" key={index}>
                                                    <div>
                                                        <label className="inline-flex items-center">
                                                            <input
                                                                type="radio"
                                                                onClick={() => onClickCategory(data.sex)}
                                                                className="form-radio border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2"
                                                                name="radio-colors"
                                                            />
                                                            <span className="text-slate-400">{data.sex}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            <label className="font-semibold">Top Products</label>
                                            <div className="block mt-2">
                                                <span className="flex items-center">
                                                    <img src={Baccarat} className="rounded shadow dark:shadow-gray-800 w-12" alt="" />
                                                    <span className="ms-3">
                                                        <span className="block font-semibold">Golden Odyssey (50ML)</span>
                                                        <span className="block text-sm text-slate-400">RS 1950</span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="block mt-2">
                                                <span className="flex items-center">
                                                    <img src={Flora} className="rounded shadow dark:shadow-gray-800 w-12" alt="" />
                                                    <span className="ms-3">
                                                        <span className="block font-semibold">Botanical Beauty</span>
                                                        <span className="block text-sm text-slate-400">RS 2200</span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="block mt-2">
                                                <span className="flex items-center">
                                                    <img src={deepsea} className="rounded shadow dark:shadow-gray-800 w-12" alt="" />
                                                    <span className="ms-3">
                                                        <span className="block font-semibold">Deep Sea</span>
                                                        <span className="block text-sm text-slate-400">RS 2200</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <button
                                                type="button"
                                                onClick={resetFilters}
                                                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full"
                                            >
                                                Reset Filter
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                                <div className="lg:col-span-9 md:col-span-8">
                                    <h3 className="text-xl leading-normal font-semibold">All Fragrances</h3>
                                </div>
                            </div>
                            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                                {currentProducts.length > 0 ? (
                                    currentProducts.map((item, index) => (
                                        <div className="group" key={index}>
                                            <div className="relative overflow-hidden shadow-sm shadow-gray-900 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md transition-all duration-500">
                                                <img src={item.image} alt="" />
                                                {item.discount ? <ul className="list-none absolute top-[10px] end-4">
                                            <li><Link className={`bg-red-600 text-white text-[10px] font-bold px-1 py-0.5 rounded h-5`}>{item.discount} Off</Link></li>
                                        </ul> : ""}
                                                <div className="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 transition-all duration-500">
                                                    <button
                                                        onClick={() => {
                                                            navigate(`/shop-item-detail/${item.id}`, {
                                                                state: { item },
                                                            });
                                                        }}
                                                        className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-900 border-slate-900 text-white w-full rounded-md"
                                                    >
                                                        <i className="mdi mdi-eye-outline mr-2"></i>
                                                        View Detail
                                                    </button>
                                                </div>

                                                {item.tag ? (
                                                    <ul className="list-none absolute top-[10px] start-4">
                                                        <li>
                                                            <Link
                                                                className={`${item.tagBg} text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5`}
                                                            >
                                                                {'Sale'}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="mt-4">
                                                <Link to={`/shop-item-detail/${item.id}`} className="hover:text-indigo-600 text-lg font-semibold">
                                                    {item.name}
                                                </Link>
                                                <div className="flex justify-between items-center mt-1">
                                                <p className="text-green-600">{item.descAmount} <del className='text-red-600 text-sm'>{item?.amount}</del> </p>
                                                    <ul className="font-medium text-amber-400 list-none">
                                                        <li className="inline">
                                                            <i className="mdi mdi-star"></i>
                                                        </li>
                                                        <li className="inline">
                                                            <i className="mdi mdi-star"></i>
                                                        </li>
                                                        <li className="inline">
                                                            <i className="mdi mdi-star"></i>
                                                        </li>
                                                        <li className="inline">
                                                            <i className="mdi mdi-star"></i>
                                                        </li>
                                                        <li className="inline">
                                                            <i className="mdi mdi-star"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <p className="text-red-600 text-sm">Inspired By {item.inspiredby} </p>

                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No products found.</p>
                                )}
                            </div>
                            <div className="flex justify-center mt-8">
                                <ul className="inline-flex items-center -space-x-px">
                                    <li>
                                        <button
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                            className="py-2 px-3 leading-tight bg-white border border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                                        >
                                            <MdOutlineKeyboardArrowLeft className="h-5 w-5" />
                                        </button>
                                    </li>
                                    {Array.from({ length: totalPages }).map((_, index) => (
                                        <li key={index}>
                                            <button
                                                onClick={() => handlePageChange(index + 1)}
                                                className={`py-2 px-3 leading-tight ${currentPage === index + 1 ? 'bg-indigo-600 text-white' : 'bg-white border border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-700'}`}
                                            >
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                    <li>
                                        <button
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                            className="py-2 px-3 leading-tight bg-white border border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                                        >
                                            <MdKeyboardArrowRight className="h-5 w-5" />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ShopFooter />
        </>
    );
}